const Footer = () => {
    return ( 
        <footer class="px-10 py-8 bg-slate-800">
            <div class="max-w-5xl mx-auto text-slate-200 text-center ">
                <span>© 2023 </span><a className="cursor-pointer hover:underline underline-offset-2" href="/" class="font-medium" target="_blank" rel="noopener">ashcodes</a> | Made with  <span className="h-auto w-auto inline-flex z-20">
<svg width="15px" height="15px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.44 3.10156C14.63 3.10156 13.01 3.98156 12 5.33156C10.99 3.98156 9.37 3.10156 7.56 3.10156C4.49 3.10156 2 5.60156 2 8.69156C2 9.88156 2.19 10.9816 2.52 12.0016C4.1 17.0016 8.97 19.9916 11.38 20.8116C11.72 20.9316 12.28 20.9316 12.62 20.8116C15.03 19.9916 19.9 17.0016 21.48 12.0016C21.81 10.9816 22 9.88156 22 8.69156C22 5.60156 19.51 3.10156 16.44 3.10156Z" fill="#db2777"/>
</svg></span> using <a className="cursor-pointer hover:underline underline-offset-2" href="https://reactjs.org/" target="_blank" rel="noopener">ReactJS</a>, <a className="cursor-pointer hover:underline underline-offset-2" href="https://tailwindcss.com" target="_blank" rel="noopener">Tailwind CSS</a> and <a className="cursor-pointer hover:underline underline-offset-2" href="https://avatarmaker.com/" target="_blank" rel="noopener">AvatarMaker</a></div>
        </footer>

     );
}
 
export default Footer;
