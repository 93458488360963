import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Work = () => {
    
    useEffect(() => {
        Aos.init({});
    }, []);

    return ( 
        <section className="bg-gray-100 py-20" name="work">
            <div className="sm:max-w-xl md:max-w-7xl mx-auto flex w-full flex-col items-start justify-center text-center md:text-left px-5 mb-3 overflow-y-hidden">
            <h2 className="text-5xl font-medium mb-4">Work</h2>
            </div>

            <div data-Aos="fade-right" className="sm:max-w-xl md:max-w-7xl mx-auto flex flex-col md:flex-row h-full justify-center items-center py-t-20 px-5 space-x-0 md:space-x-3 space-y-6 md:space-y-0">
                
            <div className="flex w-full md:w-4/12 bg-teal-400 h-48 md:h-96 bg-center bg-no-repeat cursor-pointer bg-cover workitem1 hover:opacity-90  transition duration-300 ease-in-out relative">
                    <div className="flex items-center justify-center h-full w-full opacity-0 hover:opacity-100 transition duration-300 hover:after:bg-black after:block after:h-16 after:w-full after:absolute after:h-full after:opacity-50 after:z-10">
                    <a href="https://raquelspencer.com/" className="" target="_blank">
                    <button type="button" className="flex text-white bg-slate-900 hover:bg-slate-800 hover:border-slate-800 focus:ring-1 focus:outline-none focus:ring-slate-200 font-medium rounded-md text-sm px-5 py-2.5 text-center mx-5 mb-2 relative z-20">Open page<span class="relative right-1 duration-200 ease-out group-hover:translate-x-12">
                    <svg class="w-5 h-5 ml-2 p-0.5 -rotate-45 text-teal-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span></button>
                    </a>
                    </div>
                </div>
                <div className="flex w-full md:w-4/12 bg-teal-400 h-48 md:h-96 bg-center bg-no-repeat cursor-pointer bg-cover workitem2 hover:opacity-90  transition duration-300 ease-in-out relative">
                    <div className="flex items-center justify-center h-full w-full opacity-0 hover:opacity-100 transition duration-300 hover:after:bg-black after:block after:h-16 after:w-full after:absolute after:h-full after:opacity-50 after:z-10">
                    <a href="https://helpability.com/" className="" target="_blank">
                    <button type="button" className="flex text-white bg-slate-900 hover:bg-slate-800 hover:border-slate-800 focus:ring-1 focus:outline-none focus:ring-slate-200 font-medium rounded-md text-sm px-5 py-2.5 text-center mx-5 mb-2 relative z-20">Open page<span class="relative right-1 duration-200 ease-out group-hover:translate-x-12">
                    <svg class="w-5 h-5 ml-2 p-0.5 -rotate-45 text-teal-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span></button>
                    </a>
                    </div>
                </div>
                <div className="flex w-full md:w-4/12 bg-teal-400 h-48 md:h-96 bg-center bg-no-repeat cursor-pointer bg-cover workitem3 hover:opacity-90  transition duration-300 ease-in-out relative">
                    <div className="flex items-center justify-center h-full w-full opacity-0 hover:opacity-100 transition duration-300 hover:after:bg-black after:block after:h-16 after:w-full after:absolute after:h-full after:opacity-50 after:z-10">
                    <a href="https://yerriahchambers.com/" className="" target="_blank">
                    <button type="button" className="flex text-white bg-slate-900 hover:bg-slate-800 hover:border-slate-800 focus:ring-1 focus:outline-none focus:ring-slate-200 font-medium rounded-md text-sm px-5 py-2.5 text-center mx-5 mb-2 relative z-20">Open page<span class="relative right-1 duration-200 ease-out group-hover:translate-x-12">
                    <svg class="w-5 h-5 ml-2 p-0.5 -rotate-45 text-teal-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span></button>
                    </a>
                    </div>
                </div>
                
            </div>

            <div data-Aos="fade-right" className="sm:max-w-xl md:max-w-7xl mx-auto flex flex-col md:flex-row h-full justify-center items-center px-5 space-x-0 md:space-x-3 py-3 space-y-3 md:space-y-0">
                <div className="flex w-full md:w-4/12 bg-teal-400 h-48 md:h-96 bg-center bg-no-repeat cursor-pointer bg-cover workitem4 hover:opacity-90  transition duration-300 ease-in-out relative">
                    <div className="flex items-center justify-center h-full w-full opacity-0 hover:opacity-100 transition duration-300 hover:after:bg-black after:block after:h-16 after:w-full after:absolute after:h-full after:opacity-50 after:z-10">
                    <a href="https://github.com/akshaycodeur/reagir" className="" target="_blank">
                    <button type="button" className="flex text-white bg-slate-900 hover:bg-slate-800 hover:border-slate-800 focus:ring-1 focus:outline-none focus:ring-slate-200 font-medium rounded-md text-sm px-5 py-2.5 text-center mx-5 mb-2 relative z-20">Open page<span class="relative right-1 duration-200 ease-out group-hover:translate-x-12">
                    <svg class="w-5 h-5 ml-2 p-0.5 -rotate-45 text-teal-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span></button>
                    </a>
                    </div>
                </div>
                <div className="flex w-full md:w-4/12 bg-teal-400 h-48 md:h-96 bg-center bg-no-repeat cursor-pointer bg-cover workitem5 hover:opacity-90  transition duration-300 ease-in-out relative">
                    <div className="flex items-center justify-center h-full w-full opacity-0 hover:opacity-100 transition duration-300 hover:after:bg-black after:block after:h-16 after:w-full after:absolute after:h-full after:opacity-50 after:z-10">
                    <a href="https://makemeup.developmentstage.co/" className="" target="_blank">
                    <button type="button" className="flex text-white bg-slate-900 hover:bg-slate-800 hover:border-slate-800 focus:ring-1 focus:outline-none focus:ring-slate-200 font-medium rounded-md text-sm px-5 py-2.5 text-center mx-5 mb-2 relative z-20">Open page<span class="relative right-1 duration-200 ease-out group-hover:translate-x-12">
                    <svg class="w-5 h-5 ml-2 p-0.5 -rotate-45 text-teal-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span></button>
                    </a>
                    </div>
                </div>
                <div className="flex w-full md:w-4/12 bg-teal-400 h-48 md:h-96 bg-center bg-no-repeat cursor-pointer bg-cover workitem6 hover:opacity-90  transition duration-300 ease-in-out relative">
                    <div className="flex items-center justify-center h-full w-full opacity-0 hover:opacity-100 transition duration-300 hover:after:bg-black after:block after:h-16 after:w-full after:absolute after:h-full after:opacity-50 after:z-10">
                    <a href="https://shriyaidigitals.com/" className="flex flex-row" target="_blank">
                    <button type="button" className="flex text-white bg-slate-900 hover:bg-slate-800 hover:border-slate-800 focus:ring-1 focus:outline-none focus:ring-slate-200 font-medium rounded-md text-sm px-5 py-2.5 text-center mx-5 mb-2 relative z-20">Open page<span class="relative right-1 duration-200 ease-out group-hover:translate-x-12">
                    <svg class="w-5 h-5 ml-2 p-0.5 -rotate-45 text-teal-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span></button>
                    </a>
                    </div>
                </div>
            </div>

            

        </section>

     );
}
 
export default Work;
