
import ashcodes from '../assets/ashcodes.png'
import { Link } from 'react-scroll';
import Typewriter from 'typewriter-effect'
import { useEffect } from 'react';

const Hero = () => {
    // const [state] = useEffect({
        
    // })
    return ( 
        <section className="bg-slate-900" name="hero">
            <div className="sm:max-w-xl md:max-w-7xl mx-auto flex flex-col md:flex-row h-full md:h-screen justify-center items-center py-20 px-5">
                {/* Left Column */}
                <div className="flex w-full flex-col items-start justify-center text-center md:w-3/5 md:text-left">
                    <p className="tracking-loose w-full uppercase text-gray-500">Hi! I am Ash </p>
                    <h1 className="my-4 text-5xl font-bold leading-tight text-gray-200 flex">
                    <Typewriter
                    options={{
                        strings: ['Front End Developer', 'Remote Developer', 'Ui/Ux Designer', 'Web Developer'],
                        autoStart: true,
                        loop: true,
                    }}
                    />
                    </h1>
                    

                    <p className="mb-8 text-xl leading-normal text-gray-500">I'm a web developer with a passion for building beautiful and efficient websites.
                    I specialize in HTML, CSS, JavaScript, and modern web development technologies.</p>
                    <Link to="messageme" smooth={true} duration={500} className='flex justify-center mx-auto md:ml-0'>
                    <button className="bg-teal-500 hover:bg-teal-400 text-white font-semibold py-2 px-4 rounded animate-pulse hover:animate-none">
                        Let's Talk!
                    </button>
                    </Link>
                </div>  
                <div className="w-full md:w-2/5 h-96 flex justify-center object-center">
                    <img src={ ashcodes } alt="" className='rounded-full bg-slate-800 hover:ring-8 ring-teal-300 transition duration-200'/>
                </div>
            </div>
        </section>

     );
}
//  
export default Hero;