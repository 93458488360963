import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Aboutskills = () => {
    useEffect(() => {
        Aos.init({duration:1500});
    }, []);
    return ( 
        <section className="bg-gray-200" name="aboutme">
            <div className="sm:max-w-xl md:max-w-7xl mx-auto flex flex-col md:flex-row h-full md:h-screen justify-center items-center py-20">
            
            <div className="flex w-full flex-col items-start justify-center text-center md:w-6/12 md:text-left px-5">
                <div className="intro-text">
                <h2 className="text-5xl font-medium mb-4">About</h2>
                    <p className="mb-8 text-xl leading-normal text-gray-500">I believe that understanding the fundamentals is key to solid development. I focus a lot on the vanilla part. That said, I still do use front-end/back-end frameworks</p>
                </div>
                <div className="skills-bar w-full">
                    <h5 className="text-xl mb-1">Front End Development</h5>
                    <div className="w-full bg-gray-300 h-2 mb-6">
                    <div className="bg-teal-500 h-2" style={{width: '85%'}}></div>
                    </div>

                    <h5 className="text-xl mb-1">Backend Development</h5>
                    <div className="w-full bg-gray-300 h-2 mb-6">
                    <div className="bg-teal-500 h-2" style={{width: '65%'}}></div>
                    </div>

                    <h5 className="text-xl mb-1">Wordpress Development</h5>
                    <div className="w-full bg-gray-300 h-2 mb-6">
                    <div className="bg-teal-500 h-2" style={{width: '75%'}}></div>
                    </div>

                    <h5 className="text-xl mb-1">Ui/UX Development</h5>
                    <div className="w-full bg-gray-300 h-2 mb-6">
                    <div className="bg-teal-500 h-2" style={{width: '75%'}}></div>
                    </div>
                </div>
            </div>  
            
            <div className="flex w-full items-start justify-center text-center md:w-6/12 md:text-left">

            <div className="flex justify-center">
                    <div data-aos="flip-right" className="block rounded-lg shadow-lg bg-white max-w-sm md:max-w-md">
                        <div className="py-3 px-6 border-b border-gray-300 text">
                        Skills
                        </div>
                        <div className="p-6">
                        <h5 className="text-gray-900 text-xl font-medium mb-2 text-left">Front End:</h5>
                        <p className="text-gray-700 text-base mb-4 text-left">
                        HTML5, CSS, Javascript, Jquery, AJAX, React, Nextjs, SASS/Less, Bootstrap, Materialize, Tailwind
                        </p>
                        <h5 className="text-gray-900 text-xl font-medium mb-2 text-left">Back End:</h5>
                        <p className="text-gray-700 text-base mb-4 text-left">
                        php, REST Api, MySQL
                        </p>
                        <h5 className="text-gray-900 text-xl font-medium mb-2 text-left">Platforms/CMS</h5>
                        <p className="text-gray-700 text-base mb-4 text-left">
                        Wordpress, Shopify, Squarespace, Drupal, WooCommerce, WebFlow
                        </p>
                        <h5 className="text-gray-900 text-xl font-medium mb-2 text-left">Prototyping Tools</h5>
                        <p className="text-gray-700 text-base mb-4 text-left">
                        Figma, Adobe XD, Sketch, Adobe Suite
                        </p>
                        <h5 className="text-gray-900 text-xl font-medium mb-2 text-left">Other Tools</h5>
                        <p className="text-gray-700 text-base mb-4 text-left">
                        GitHub, Gulp, Grunt, Handlebars
                        </p>
                        </div>
                        <div className="py-3 px-6 border-t border-gray-300 text-gray-600">
                        And continuously improving...
                        </div>
                    </div>
                </div>

            </div>  

            </div>
        </section>
     );
}
 
export default Aboutskills;