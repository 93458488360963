import { useState } from "react";

import {Link} from "react-scroll"

const Navbar = () => {

    const [nav,setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    return ( 
        <nav id="header" className="fixed w-full z-30 top-0 text-white bg-slate-900">

		<div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2 max-w-7xl">

			<div className="pl-4 flex items-center">
				<a className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl md:text-4xl" href="#">ash<span className="bg-gradient-to-br from-teal-500 to-emerald-300 bg-clip-text text-transparent">codes</span>
				</a>
			</div>

			<div className="w-full flex-grow md:flex md:items-center md:w-auto md:block mt-2 md:mt-0 bg-white md:bg-transparent text-slate-50p-4 md:p-0 z-20 hidden" id="nav-content">
				<ul className="list-reset md:flex justify-end flex-1 items-center">
					<li className="mr-3 inline-block no-underline hover:text-teal-600 cursor-pointer py-2 px-4 text-slate-50">
						<Link to='hero' smooth={true} duration={500}>
						Home
						</Link>
					</li>
					<li className="mr-3 inline-block no-underline hover:text-teal-600 cursor-pointer py-2 px-4 text-slate-50">
						<Link to='aboutme' smooth={true} duration={500}>
						About
						</Link>
					</li>
					<li className="mr-3 inline-block no-underline hover:text-teal-600 cursor-pointer py-2 px-4 text-slate-50">
						<Link to='work' smooth={true} duration={500}>
						Work
						</Link>
					</li>
					<li className="mr-3 inline-block no-underline hover:text-teal-600 cursor-pointer py-2 px-4 text-slate-50">
						<Link to='messageme' smooth={true} duration={500}>
						Contact
						</Link>
					</li>
				</ul>
			</div>


            {/* Kamburger */}
            <div className="grid md:hidden pr-4 z-10" onClick={handleClick}>
				<button id="nav-toggle" className="flex flex-col hover:text-gray-800 space-y-1 absolute right-10 top-2">
                    <span className="w-6 h-1 bg-white block"></span>
                    <span className="w-6 h-1 bg-white block"></span>
                    <span className="w-6 h-1 bg-white block"></span>
				</button>
			</div>
            

            {/* Mobile Menu */}
            <div className="mobile-menu">
                <ul className={!nav ? 'h-0 w-0 translate-x-100 transition duration-500 opacity-0' : 'absolute top-0 right-0 flex flex-col justify-center items-center bg-teal-700 bg-opacity-90 h-screen transition-all w-1/2 duration-600 ease-in duration-300 space-y-8 translate-x-0 opacity-100'}>
					<li className="mr-3 inline-block no-underline hover:text-teal-300 cursor-pointer py-2 px-4 text-slate-50 text-2xl">
						<Link to='hero' smooth={true} duration={500}>
						Home
						</Link>
					</li>
					<li className="mr-3 inline-block no-underline hover:text-teal-300 cursor-pointer py-2 px-4 text-slate-50 text-2xl">
						<Link to='aboutme' smooth={true} duration={500}>
						About
						</Link>
					</li>
					<li className="mr-3 inline-block no-underline hover:text-teal-300 cursor-pointer py-2 px-4 text-slate-50 text-2xl">
						<Link to='work' smooth={true} duration={500}>
						Work
						</Link>
					</li>
					<li className="mr-3 inline-block no-underline hover:text-teal-300 cursor-pointer py-2 px-4 text-slate-50 text-2xl">
						<Link to='messageme' smooth={true} duration={500}>
						Contact
						</Link>
					</li>
                </ul>
            </div>
		</div>

	</nav>



     );
}
 
export default Navbar;